var $ = window.$ = window.jQuery = require('jquery');
require('jquery-ui');

var select2 = require('select2-browserify');
var vm = require('./vm');

require('bootstrap');

$(function () {
    function details_in_popup(url, word, annotation, div_id) {
        var params = {
            csrfmiddlewaretoken: _csrf,
            word: word,
            annotation: annotation
        };
        $.post(url, params, function (response, status) {
            $('#'+div_id).html(response);
        });
        return '<span id="'+ div_id +'">Loading...</div>';
    }
    var re = / /g;
    
    function attribs(atr){
        return atr.replace(re, '_');
    }
    
    function norm_word(word){
        word = word.replace(/^[?]*/, '');
        word = word.replace(/[?]*$/, '');
        return word;
    }


    try {
        $('body').popover({
            selector: '.word',
            container: 'body',
            trigger: 'hover',
            delay: { "show": 600, "hide": 100 },
            placement: 'auto right',
            html: true,
            content: function(){
                var div_id =  "tmp-id-" + $.now();
                var word = norm_word($(this).text());
                var annotation = attribs($(this).attr('title'));
                return details_in_popup('/api/senses', word, annotation, div_id);
            }
        });
        
    } catch (e) {
        console.error(e);    
    }
    try {
        $('.meta-select').select2({placeholder: "Select a State"});
        
    } catch (e) {
        console.error(e);
    }

        
    var search = vm.SearchVM();
    $('#results').on('mouseover', '.align', function () {
        // console.error('over');
        var classList = $(this).attr('class').split(/\s+/);
        $.each(classList, function (index, item) {
            if (item.match(/^al_/) !== null) {
                $('.' + item).addClass('aligned');
            }
        });
    });
    $('#results').on('mouseout', '.align', function () {
        var classList = $(this).attr('class').split(/\s+/);
        $.each(classList, function (index, item) {
            if (item.match(/^al_/) !== null) {
                $('.' + item).removeClass('aligned');
            }
        });
    });
    

});
