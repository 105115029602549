var ko = require('knockout');

module.exports.SearchVM = function () {
    // constants
    var ri = 0;
    function Result(result, page) {
        var self = this;
        self.file = result[0];
        self.id = result[1];
        self.doc = result[2];
        self.details = ko.observable();
        self.uid = self.file.replace(/[\/.]/g, '') + self.id;
        self.i = ri;
        self.page = page;
        ri += 1;
    }

    function ViewModel () {
        var self = this;
        self.request_language = _requestlanguage;
        self.features = _features;
        self.query = ko.observable("");
        self.page = ko.observable();
        self.count = ko.observable();
        self.pages = ko.observable();
        self.results = ko.observableArray([]);
        self.has_next = ko.observable();
        self.has_next_next = ko.observable();
        self.has_previous = ko.observable();
        self.has_previous_previous = ko.observable();
        self.page_start_index = ko.observable()
        self.status = ko.observable();
        self.sort = ko.observable('left');
        self.desc = ko.observable(false);
        self.error = ko.observable();

        self.assistant_word = ko.observable();
        self.assistant_rel = ko.observable();
        self.assistant_features = {};
        self.assistant_possible = {};
        self.assistant_from = ko.observable();
        self.assistant_to = ko.observable();

        self.alignments = ko.observableArray();

        for (var p = 0; p < _features.length; p++) {
            //console.error(_features[p][0]);
            self.assistant_features[_features[p][0]] = ko.observableArray();
            self.assistant_possible[_features[p][0]] = {};
            for (var v = 0; v < _features[p][1][1].length; v++) {
                var value = _features[p][1][1][v][1];
                self.assistant_possible[_features[p][0]][value] = 1;
            }
        }

        var meta_fields = {};
        // var meta_keys = Object.keys(_meta).sort();
        meta_keys = [
            'author',
            'domain_info',
            'domain1',
            'genre',
            'medium',
            'publishing_date',
            'source_type',
            'style',
            'translated',
            'translator',
            'year_of_creation_from',
            'year_of_creation_to',
            'year_of_creation'
        ];

        for (p = 0; p < meta_keys.length; ++p){
            var key = meta_keys[p];
            meta_fields[key] = ko.observable();
        };

        self.meta_fields = meta_fields;
        self.meta_keys = meta_keys;
        
        self.search_query = function () {
            if (!self.query().length) return;
            self.results([]);
            self.search({page: 1});
        }

        self.key = function (html, d) {
            var split = self.split(html);
            var spans = split[d];
            if (d == 'left') {
                spans.push(split['right'][0]);
                spans.reverse();
            }
            var k = spans.join(' ').replace(/<[^>]+>/g, '').toLocaleLowerCase();
            return k;
        };

        self.sorted_results = ko.computed(function () {
            var results = self.results();
            results.sort(function (a, b) {
                var x = self.key(a.doc, self.sort());
                var y = self.key(b.doc, self.sort());
                result = ((x < y) ? -1 : ((x > y) ? 1 : 0));
                if (self.desc()) result = -result;
                return result;
            });

            return results;
        });

        self.showElement = function(elem, index, data) {
            if (elem.nodeType === 1 && data.page > 0 && data.page == self.page()) {
                var x = $(elem).find('.panel-warning')
                x.hide().fadeIn(600);
                //x.switchClass('panel-warning', 'panel-default', 1000);
                //x.switchClass('panel-default', 'panel-warning', 1000);
            };
        }

        self.search = function (data) {
            // remove the inapproriate keys from the meta to send
            var meta_to_send = ko.toJS(self.meta_fields);
            delete meta_to_send.year_of_creation_to;
            delete meta_to_send.year_of_creation_from;

            var params = {
                csrfmiddlewaretoken: _csrf,
                page: data.page,
                query: JSON.stringify({
                    query: self.query(),
                    meta: meta_to_send,
                    aligns: self.alignments()
                    //aligns: []
                })
            };

            if (data.page == 1 && data.noloading != true) self.status('loading');
            $.post('/api/search', params, function(data, status){
                var results = ko.utils.arrayMap(data.results, function(result) {
                    return new Result(result, params.page);
                });
                self.results([]);
                if (data.error) {
                    self.error(data.error);
                    self.status('error');
                } else {
                    self.count(data.count);
                    self.page(data.page);
                    self.results.push.apply(self.results, results);
                    self.last_query = self.query();
                    self.last_meta = ko.toJS(self.meta_fields)
                    self.has_next(data.has_next);
                    self.has_next_next(data.has_next_next);
                    self.has_previous(data.has_previous);
                    self.has_previous_previous(data.has_previous_previous);
                    self.page_start_index(data.page_size * (data.page - 1) + 1);
                    self.pages(data.pages);
                    self.status('results');
                }
            }, 'json');

        };

        self.toggle_appropriate_subcategories = function(category) {
            var domElement = $(event.target)[0];
            if (category == "POS") {
                $('#NT, #G, #GF, #N, #D, #VA, #VT, #P, #T, #IVF, #PT, #NUMT').css("display","none");
                if ($('#checkbox_N').is(':checked')) {
                    // console.log("ckeckbox_N is checked");
                    $('#G, #NT, #N, #D').css("display","block");
                }
                if ($('#checkbox_V').is(':checked')) {
                    $('#VA, #VT, #T, #P, #N, #IVF, #GF, #D').css("display","block");
                }
                if ($('#checkbox_A').is(':checked')) {
                    $('#GF, #N, #D').css("display","block");
                }
                if ($('#checkbox_NUM').is(':checked')) {
                    $('#GF, #N, #D, #NUMT').css("display","block");
                }
                if ($('#checkbox_P').is(':checked')) {
                    $('#GF, #N, #D, #PT').css("display","block");
                }
            }
        }

        self.change_language = function() {
            var params = {
                csrfmiddlewaretoken: _csrf,
                query: self.last_query
            };
            $.post('/api/main', params, function(xhr) {
                if(xhr == "language changed") {
                    location.reload(true);
                }  
            });
        };

        function load_details(result) {
            if (result.details()) return;
            var params = {
                csrfmiddlewaretoken: _csrf,
                query: self.last_query,
                file: result.file,
                id: result.id
            };

            $.post('/api/details', params, function(data, status){
                result.details(data);
                //console.error(result.details());
            }, 'json');
        };

        self.load_next = function () {
            self.search({page: self.page() + 1, query: self.last_query});
        };

        self.load_next_next = function () {
            self.search({page: self.page() + 2, query: self.last_query});
        };

        self.load_previous = function () {
            self.search({page: self.page() - 1, query: self.last_query, noloading: true});
        };

        self.load_previous_previous = function () {
            self.search({page: self.page() - 2, query: self.last_query, noloading: true});
        };

        self.load_page = function(page_number) {
            self.search({page: parseInt(page_number), query: self.last_query, noloading: true});
        };

        self.details = function (i) {
            load_details(i);
        };

        self.col = function () {
            return true;
        };

        self.left = function (html) {
            var spans = html.match(/<span.*?<\/span>/g);
            var i = 0;
            while (i < spans.length && spans[i].indexOf('<B>') == -1) {
                //console.error(spans[i]);
                i += 1;
            }
            //console.error(spans.length, i);
            return spans.slice(0, i).join(' ');
        };

        self.right = function (html) {
            var spans = html.match(/<span.*?<\/span>/g);
            var i = 0;
            while (i < spans.length && spans[i].indexOf('<B>') == -1) {
                i += 1;
            }
            return spans.slice(i).join(' ');
        };

        self.split = function (html) {
            var spans = html.match(/<span.*?<\/span>/g);
            var i = 0;
            while (i < spans.length && spans[i].indexOf('<B>') == -1) {
                i += 1;
            }
            var result = {
                left: spans.slice(0, i),
                right: spans.slice(i)
            };
            return result;

        };

        var _sort = function (dir) {
            if (self.sort() == dir) {
                self.desc(!self.desc());
            }
            self.sort(dir);
        }

        self.sort_left = function () {
            _sort('left');
        };

        self.sort_right = function () {
            _sort('right');
        };

        // Assistant
        var last_word = function(str) {
            var m = /([^}{\] ]*\s*)$/.exec(str);
            var word;
            if (m) {
                word = m[1];
                if (word[0] == ' ') {
                    word = '';
                }
            }
            return word;
        };


        self.assistant = function () {
            var delim = '##';
            var in_range = function (str, c) {
                //var regex = new RegExp('[0-9]*' + delim + '[0-9]*');
                self.assistant_from(undefined);
                self.assistant_to(undefined);
                try {
                    var regex = new RegExp('\\[([0-9, ]*)' + delim + '([0-9, ]*)\\]');
                    var m = regex.exec(str);
                    if (m) {
                        var r = (m[1]+m[2]).split(',');
                        self.assistant_from(+r[0]);
                        self.assistant_to(+r[1]);
                        self.assistant_begin = c - m[1].length - 1;
                        self.assistant_length = m[1].length + m[2].length + 2;
                    }
                } catch (e) {

                }
            }

            var word = '', features = '', replacer = '_';
            var word_features;

            var c = document.getElementById('input-search').selectionStart;

            var query = self.query() || '';
            str = query;
            //str = str.replace(/(\s*)(\/[FHLS]\/)/g, function (str, $1, $2) {
            //    return Array($1.length + 1).join('_') + $2;
            //});
            var left = str.slice(0, c);
            var right = str.slice(c, str.length);

            left = left.replace(/\s*\//g, '/');
            right = right.replace(/\s*\//g, '/');
            c = left.length;

            var begin = c;
            query = left + right;
            self.query(left + right);

            var specials = /[<>&|!]/g;

            left = left.replace(specials, ' ');
            right = right.replace(specials, ' ');

            str = left + delim + right;
            //console.error(str);
            in_range(str, c);
            if (!self.assistant_from()) {

                var regex = new RegExp('(\{[^{}]*)' + delim + '([^{}]*\})');
                var split_regex = new RegExp(delim + '\\s*(\{[^}]*\})');
                var m = regex.exec(str);
                if (m) {
                    word = last_word(str.slice(0, m.index));
                    begin = m.index - word.length;
                    features = m[1] + m[2];
                } else {
                    var word_regex = new RegExp('([^ }])*' + delim + '([^ {\[]*\\s*)');
                    var w_m = word_regex.exec(str);
                    if (w_m) {
                        if (w_m[0].trim().length === delim.length) {
                            //console.error('yes');
                            //var split_regex = new RegExp(delim + '\s+');
                            var s_m = split_regex.exec(str);
                            //console.error('sm', s_m);
                            if (s_m) {
                                word = last_word(left);
                                begin = c - word.length;

                                features = s_m[1];
                            }
                        } else if (w_m[1]){
                            word = last_word(left)
                            word += w_m[2];

                            //console.error('word', word);
                            //console.error('w_m', w_m[2]);
                            begin = c - word.length + w_m[2].length;

                            features = /(\{[^}]*\})?/.exec(right.slice(w_m[2].length))[1] || '';
                        } else {
                            word = w_m[2];
                            begin = c;
                            features = /(\{[^}]*\})?/.exec(right.slice(w_m[2].length))[1] || '';
                        }
                    }
                }
                var relation = '';
                self.assistant_begin = begin;
                self.assistant_length = (word+features).trim().length;
                var rel_m = /^(.*)\/(.)\//.exec(word);
                if (rel_m) {
                    word = rel_m[1];
                    relation = rel_m[2];
                }
                if (word.trim() === '*') {
                    word = '';
                    relation = '';
                }

                self.assistant_word(word.trim());
                self.assistant_rel(relation);
                self.set_features(features);

                word_features = {word: word, relation: relation, features: features};
                // console.error(word_features);
            }
            $('#assistant_modal').modal();

        };

        self.set_features = function (str) {
            str = str.replace(/\s*=\s*/g, '=');
            var r = /([0-9a-zA-Z]+)=([0-9a-zA-Z]+)/g;
            var m, key, value;

            for (k in self.assistant_features) {
                self.assistant_features[k]([]);
            }

            while ( (m=r.exec(str))!= null ) {
                key = m[1];
                value = m[2];
                if (self.assistant_features.hasOwnProperty(key) && self.assistant_possible[key][value]) {
                    self.assistant_features[key].push(value);
                }
            }
        };

        self.get_features = function () {
            var fs = [];
            for (key in self.assistant_features) {
                var values = self.assistant_features[key]();
                for (var i = 0; i < values.length; ++i) {
                    fs.push(key + '=' + values[i]);
                }
            }
            if (fs.length) {
                return '{'+ fs.join(' ') + '}'
            }
            return '';
        };

        self.assistant_submit = function () {
            var query = self.query();
            var assistant_query;
            if (!self.assistant_from()) {
                var word = self.assistant_word();
                var features = self.get_features();
                var relation = (word.length && self.assistant_rel())? '/' + self.assistant_rel() + '/': '';
                if (features.length > 1 && word.length < 1) {
                    word = '*';
                }
                assistant_query = word + relation + features;
            } else {
                assistant_query = '[' + self.assistant_from() + ', ' + self.assistant_to() + ']';
            }
            self.query(query.slice(0, self.assistant_begin) + assistant_query + query.slice(self.assistant_begin + self.assistant_length));
        }

        self.make_year_of_creation = function() {
            var year_from = self.meta_fields["year_of_creation_from"]();
            var year_to = self.meta_fields["year_of_creation_to"]();
            if (year_from && year_from.trim() != '' && year_to && year_to.trim() != '') {
                return '[' + year_from + ':' + year_to + ']';
            }
            if (year_from && year_from.trim() != '') {
                return '[' + year_from + ':' + new Date().getFullYear() + ']';
            }
            if (year_to && year_to.trim() != '') {
                return '[1900:' + year_to + ']';
            } 
            return undefined;
        }

        self.meta_submit = function () {
            for (var p=0; p < self.meta_keys.length; p++) {
                var key = self.meta_keys[p];
                var value = self.meta_fields[key]();
                if (value && value.trim() == '') {
                    self.meta_fields[key](undefined);
                }
            }
            self.meta_fields['year_of_creation'](self.make_year_of_creation());
        };

        self.align = function () {
            $('#align_modal').modal();
        };

        self.meta = function () {
            $('#meta_modal').modal();
        };

        var datalists = [
            "accessibility",
            "translator",
            "source_type",
            "source",
            "translated",
            "medium",
            "style",
            "genre",
            "genre_info",
            "domain1",
            "domain2",
            "quality",
            "accessibility"
        ];

        var input;

        // method generating dropdowns for some of the meta fields created in /dcl/wlsng/wlsng/apps/search/lucene_search/term.py by the example hash
        self.generate_dropdown = function () {
            input = String(document.activeElement.id);
            var dataList = document.getElementById(input + '-list');
            if (datalists.indexOf(input) != -1) {
                
                // Create a new XMLHttpRequest.
                var request = new XMLHttpRequest();

                // Handle state changes for the request.
                request.onreadystatechange = function(response) {
                    if (request.readyState === 4) {
                        if (request.status === 200) {
                        // Parse the JSON
                            var jsonOptions = JSON.parse(request.responseText);

                            // If there are no options to the datalist (prevents appending the same options)
                            if (dataList.options.length == 0) {
                                // Loop over the JSON array.
                                jsonOptions.forEach(function(item) {
                                // Create a new <option> element.
                                    var option = document.createElement('option');
                                    // Set the value using the item in the JSON array.
                                    option.value = item;
                                    // Add the <option> element to the <datalist>.
                                    dataList.appendChild(option);
                                });
                            }                            
                        }
                    }
                };

                // Set up and make the request.
                if (self.request_language == "bg") {
                    request.open('GET', '/static/js/datalists/' + input + '_bg.json', true);
                }
                else {
                    request.open('GET', '/static/js/datalists/' + input + '.json', true);
                }
                request.send();
            }
        };

        var translations = {
            "filename": ["име на файл", "filename"],
            "author": ["автор", "author"],
            "translator": ["преводач", "translator"],
            "title": ["заглавие", "title"],
            "year_of_creation_from": ["година на създаване от", "year of creation from"],
            "year_of_creation_to": ["година на създаване до", "year of creation to"],
            "year_of_creation": ["година на създаване", "year of creation"],
            "publishing_date": ["дата на публикуване", "publishing date"],
            "source_type": ["тип източник", "source type"], 
            "source": ["източник", "source"],
            "translated": ["превод", "translated"],
            "medium": ["форма", "medium"],
            "number_of_words": ["брой думи", "number of words"],
            "style": ["стил", "style"],
            "genre": ["жанр", "genre"],
            "genre_info": ["информация за жанр", "genre info"],
            "domain1": ["подкатегория", "category1"],
            "domain2": ["подкатегория2", "category2"],
            "domain_info": ["категория", "category"],
            "notes": ["забележки", "notes"],
            "keywords": ["ключови думи", "keywords"],
            "quality": ["качество", "quality"],
            "accessibility": ["достъпност", "accessibility"],
            "bg": ["български", "bg"],
            "en": ["английски", "en"],
            "име на файла": ["име на файл", "filename"],
            "тематична област": ["подкатегория", "category1"],
            "тематична област 2": ["подкатегория2", "category2"],
            "информация за жанра": ["информация за жанр", "genre info"],
            "медиум": ["форма", "medium"],
            "информация за тематичната област": ["категория", "category"]
        };

        self.translate_meta = function (to_be_translated) {
            if (!translations[to_be_translated]) {
                return to_be_translated;
            }
            if (self.request_language == "bg") {
                return translations[to_be_translated][0];
            }
            else {
                return translations[to_be_translated][1];
            }
        };

        self.get_request_language = function () {
            return self.request_language;
        };

        self.create_meta_element = function(el) {
            if (el == 'year_of_creation') {
                return false;
            }
            return true;
        };
    }

    var vm = new ViewModel();
    ko.applyBindings(vm);

};
